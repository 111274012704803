<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-4 offset-md-8">
                <b-input-group class="mt-3">
                    <b-form-input v-model="payload.user" placeholder="Search by username or email"  />
                    <b-input-group-append>
                    <b-button variant="primary" @click="getData()">Search user</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
        <b-table v-if="loading == false" class="mt-3" responsive :items="team" :fields="fields">
            <template #cell(username)="row">
                {{ row.item.user.username }}
            </template>
            <template #cell(document)="row">
                {{ row.item.document }}
            </template>
            <template #cell(address)="row">
                {{ row.item.address }}, {{ row.item.city }}, {{ row.item.country }}
            </template>
            <template #cell(doc)="row">
                <a :href="row.item.image " target="_blank">
                    Download: {{ row.item.image }}
                </a>
            </template>
            <template #cell(type)="row">
                {{ row.item.type }}
            </template>
            <template #cell(status)="row">
                {{ row.item.document }}
            </template>
        </b-table>
        <Loading type="table" v-else/>
        <div class="text-right">
            <b-button-group>
                <b-button variant="outline-primary" @click="payload.page = payload.page - 1" :disabled="total.current_page == total.first_page">Preview</b-button>
                <b-button variant="outline-primary" @click="payload.page = payload.page + 1" :disabled="total.current_page == total.last_page">Next</b-button>
            </b-button-group>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    data () {
        return {
            loading: false,
            payload: {
                page: 1,
                user: null
            },
            total: 0,
            fields: [
                {tdClass: 'text-left', thClass: 'text-left', key: 'username', label: 'Username' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'document', label: 'Document' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'address', label: 'Address' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'doc', label: 'Doc' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'type', label: 'Type' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'status', label: 'Options' }
            ],
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('user', ['getVerificateKyc']),
        getData() {
            this.loading = true
            this.getVerificateKyc(this.payload).then(response => {
                this.loading = false
                this.total = response
            })
            this.loading = false
        }
    },
    computed: {
        ...mapState('user', ['team'])
    },
    watch: {
        'payload.page': function (val) {
            this.payload.page = val
            this.getData()
        }
    }
}
</script>